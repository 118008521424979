<template>
  <div class="fault-content-3">
    <div class="content-1">
      <div class="top display-flex">
        <div class="left">
          <div class="display-flex texts">故障状态统计</div>
          <div class="chart">
            <chart
              :cdata="gz_bar_options"
              :id="'gz_bar'"
              :height="'calc(38vh)'"
            />
          </div>
        </div>
        <div class="center">
          <div class="display-flex">
            <span class="text-s">站点名称</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button class="btn" type="primary">+影响因子上传</el-button>
          </div>
          <div class="display-flex">
            <div class="status position1">
              <div class="circle"></div>
              <div class="text">过热</div>
            </div>
            <div class="status position2">
              <div class="circle"></div>
              <div class="text">绕组短路</div>
            </div>
            <div class="status position3">
              <div class="circle"></div>
              <div class="text">绝缘老化</div>
            </div>
            <div class="status1 position4">
              <div class="circle1"></div>
              <div class="text">负荷因素</div>
            </div>
            <div class="status1 position5">
              <div class="circle1"></div>
              <div class="text">天气因素</div>
            </div>
            <div class="status1 position6">
              <div class="circle1"></div>
              <div class="text">历史故障</div>
            </div>
          </div>
          <div class="display-flex bottomX">
            <el-image :src="require('../image/dizuo.png')"></el-image>
          </div>
        </div>
        <div class="right">
          <div class="display-flex texts">原因分析</div>
          <div class="display-flex textsub">(1) 放电故障原因</div>
          <div class="display-flex content-text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;绝缘油老化变质；带电度升高；抗静电剂逐年消耗；绝缘表面粗糙；绝缘局部高电场强度；油路设计不合理，绝缘油含硫多，加速绝缘油老化。
          </div>
          <div class="display-flex textsub">(2) 振动故障原因</div>
          <div class="display-flex content-text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;变压器铁芯的磁致伸缩除引起噪声外，还使变压器铁芯和本体发生振动。振动严重时，导致变压器有关部件的松动，引起发热、放电或部件脱落。
          </div>
          <div class="display-flex textsub">(3) 过热故障原因</div>
          <div class="display-flex content-text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;无励磁分接开关动静触头接触不良、静触柱引线焊接不良、线圈出线与套管连接不良、穿缆式套管线鼻子焊接不良、穿缆式裸引线与套管导杆内壁接触过热、多股并绕线圈出线处焊接不良、冷压接头等。
          </div>
        </div>
      </div>
      <div class="bottom display-flex">
        <div class="left">
          <div class="display-flex texts">设备点位故障详情</div>
          <div class="display-flex subt">
            <div style="flex: 1">故障点位分布</div>
            <div style="flex: 1">故障设备类型</div>
          </div>
          <div class="display-flex">
            <div class="chart">
              <chart
                :cdata="dianweiOptions"
                :id="'gz_tu'"
                :height="'calc(14vh)'"
              />
            </div>
            <div class="chart">
              <chart
                :cdata="gz_fenbu_options"
                :id="'gz_fenbu'"
                :height="'calc(14vh)'"
              />
            </div>
          </div>
          <div class="display-flex subt">智能决策记录</div>
          <div class="table">
            <el-table
              :data="tableData"
              height="calc(15vh)"
              border
              style="width: 98%; margin-left: 1%"
              @row-click="goToMassage"
              :row-class-name="tableRowClassName"
            >
              <el-table-column
                v-for="(item, index) in tableTitleList"
                :width="item.width"
                :label="item.name"
                :key="index"
                :prop="item.prop"
                :sortable="item.sortable"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
          <div class="display-flex pages">共{{ tableData.length }}条</div>
        </div>
        <div class="right">
          <div class="display-flex texts">智能决策建议</div>
          <div class="display-flex textsub">(1) 放电故障处理建议</div>
          <div class="display-flex content-text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于轻微放电，建议加强监测并观察发展趋势，同时考虑调整设备运行工况以减少放电。
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于严重放电，建议立即停机检查，并对放电部位进行清洁、修复或更换绝缘部件。
          </div>
          <div class="display-flex textsub">(2) 温度故障处理建议</div>
          <div class="display-flex content-text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于传感器故障，建议更换新的传感器并重新校准。
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于散热系统问题，建议清理散热通道、更换故障部件或优化散热设计。
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于过载引起的温升，建议调整设备运行参数或增加散热设备以降低温度。
          </div>
          <div class="display-flex textsub">(3) 振动故障处理建议</div>
          <div class="display-flex content-text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于轻微振动异常，建议加强监测并观察发展趋势，同时考虑调整设备运行工况以减少振动。
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于严重振动异常，建议立即停机检查，并对振动源进行修复或更换部件。
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;定期进行设备维护和保养，预防振动故障的发生。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "./echarts.vue";
export default {
  components: { chart },
  data() {
    return {
      num: 64,
      options: [
        {
          value: "1",
          label: "站点1",
        },
        {
          value: "2",
          label: "站点2",
        },
        {
          value: "3",
          label: "站点3",
        },
        {
          value: "4",
          label: "站点4",
        },
        {
          value: "5",
          label: "站点5",
        },
      ],
      typeoptions: [
        {
          value: "1",
          label: "温度过热",
        },
        {
          value: "2",
          label: "局放prpd",
        },
        {
          value: "3",
          label: "振动异常",
        },
      ],
      shijian: "1",
      selectIndex: "",
      tableData: [
        {
          date: "2016-05-01",
          content: "xx号传感器1",
          des: "A相",
          prpdid: "100008923",
        },
        {
          date: "2016-05-02",
          content: "xx号传感器10",
          des: "B相",
          prpdid: "100008923",
        },
        {
          date: "2016-05-03",
          content: "xx号传感器2",
          des: "B相",
          prpdid: "100008923",
        },
        {
          date: "2016-05-04",
          content: "xx号传感器2",
          des: "C相",
          prpdid: "100008923",
        },
        {
          date: "2016-05-05",
          content: "xx号传感器3",
          des: "A相",
          prpdid: "100008923",
        },
        {
          date: "2016-05-06",
          content: "xx号传感器3",
          des: "B相",
          prpdid: "100008923",
        },
        {
          date: "2016-05-07",
          content: "xx号传感器4",
          des: "C相",
          prpdid: "100008923",
        },
      ],
      value: "1",
      gz_bar_options: {},
      gz_fenbu_options: {},
      dianweiOptions: {},
      zd_pie_options: {},
      tableTitleList: [
        { name: "故障编号", prop: "prpdid", sortable: false },
        { name: "故障发生时间", prop: "date", sortable: true },
        { name: "决策内容", prop: "content", sortable: false },
        { name: "故障描述", prop: "des", sortable: true }
      ],
    };
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      this.setPrpdBarOptions();
      this.setZdPieOptions();
      this.setDianweiOptions();
    },
    setPrpdBarOptions() {
      var scaleData = [
        {
          name: "正常",
          value: 20,
          color: "#22B14C",
        },
        {
          name: "注意",
          value: 10,
          color: "ff0000",
        },
        {
          name: "异常",
          value: 10,
          color: "FF6800",
        },
        {
          name: "故障",
          value: 10,
          color: "F6FF02",
        },
      ];
      var rich = {
        white: {
          color: "#000",
          fontSize: 20,
          align: "center",
          padding: [0, 0],
        },
      };
      var data = [];
      var color = ["#000", "#000", "#ff0000", "#FF6800", "#F6FF02"];
      for (var i = 0; i < scaleData.length; i++) {
        data.push({
          value: scaleData[i].value,
          name: scaleData[i].name,
          itemStyle: {
            normal: {
              fontSize: 20,
              borderWidth: 4,
              shadowBlur: 200,
              borderColor: scaleData[i].color,
            },
          },
        });
      }
      var seriesObj = [
        {
          name: "",
          type: "pie",
          color: ["#5DC816", "#F6FF02", "#FD6700", "#ffa800"],
          grid: {
            show: true,
            top: "1%",
            left: "1%",
            right: "1%",
            bottom: "1%",
          },
          clockWise: false,
          radius: [50, 160],
          hoverAnimation: true,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "inside",
                textStyle: {
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: 20,
                },
                formatter: function (params) {
                  var percent = 0;
                  var total = 0;
                  for (var i = 0; i < scaleData.length; i++) {
                    total += scaleData[i].value;
                  }
                  percent = ((params.value / total) * 100).toFixed(0);
                  if (params.name !== "") {
                    return (
                      params.value +
                      "\n" +
                      params.name +
                      "{white|" +
                      "占比" +
                      percent +
                      "%}"
                    );
                  } else {
                    return "";
                  }
                },
                rich: rich,
              },
              labelLine: {
                length: 0,
                length2: 0,
                show: true,
                color: "#fff",
              },
            },
          },
          data: data,
        },
      ];
      this.gz_bar_options = {
        backgroundColor: "transparent",
        tooltip: {
          show: false,
        },
        legend: {
          orient: "vertical",
          left: "0%",
          data: ["正常", "注意", "异常", "故障"],
          textStyle: {
            color: "#fff",
          },
        },
        toolbox: {
          show: false,
        },
        series: seriesObj,
      };
    },
    setZdPieOptions() {
      var data = [
        {
          value: 3661,
          name: "开关柜",
        },
        {
          value: 5713,
          name: "变压器",
        },
        {
          value: 9938,
          name: "电缆",
        },
      ];

      this.gz_fenbu_options = {
        backgroundColor: "transparent",
        title: {
          text: "",
        },
        tooltip: {
          show: false,
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "0%",
          data: ["开关柜", "变压器", "电缆"],
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            type: "pie",
            selectedMode: "single",
            radius: ["40%", "88%"],
            color: ["#86D560", "#AF89D6", "#59ADF3", "#FF999A", "#FFCC67"],

            label: {
              normal: {
                position: "inner",
                formatter: "{d}%",

                textStyle: {
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 14,
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: data,
          },
        ],
      };
    },
    setDianweiOptions() {
      var formatUtil = this.$echarts.format;
      var dtlData = [
        {
          name: "站点一",
          value: 30,
          children: [
            {
              name: "开关柜",
              value: 10,
            },
            {
              name: "变压器",
              value: 10,
            },
            {
              name: "电缆",
              value: 10,
            },
          ],
        },
        {
          name: "站点二",
          value: 30,
          children: [
            {
              name: "开关柜",
              value: 10,
            },
            {
              name: "变压器",
              value: 10,
            },
            {
              name: "电缆",
              value: 10,
            },
          ],
        },
        {
          name: "站点三",
          value: 30,
          children: [
            {
              name: "开关柜",
              value: 10,
            },
            {
              name: "变压器",
              value: 10,
            },
            {
              name: "电缆",
              value: 10,
            },
          ],
        },
      ];
      var mapping = {
        开关柜: "开关柜",
        变压器: "变压器",
        电缆: "电缆",
      };
      var color = [
        "#6F3FE1",
        "#5781FD",
        "#4DB1CB",
        "#3EBD7C",
        "#F7A925",
        "#bda29a",
        "#ca8622",
        "#749f83",
        "#6e7074",
        "#546570",
        "#c4ccd3",
      ];
      var bsLine = [];
      var bsLineV = {};
      for (var key in dtlData) {
        bsLine.push(dtlData[key].name);
        bsLineV[dtlData[key].name] = dtlData[key].value;
      }
      // 层级样式
      function getLevelOption1() {
        return [
          {
            color: color,
            itemStyle: {
              normal: {
                borderWidth: 0,
                borderColor: "#fff",
                gapWidth: 2,
              },
            },
          },
          {
            //colorSaturation: [0.35, 0.6],
            colorAlpha: [1, 0.5],
            upperLabel: {
              normal: {
                color: "#555555",
                show: true,
                height: 30,
              },
            },
            itemStyle: {
              normal: {
                borderWidth: 5,
                borderColor: "#EFEFEF",
                gapWidth: 1,
              },
              emphasis: {
                borderColor: "#ccc",
              },
            },
          },
        ];
      }

      var series0 = {
        name: "",
        type: "treemap",
        itemStyle: {
          normal: {
            borderColor: "transparent",
          },
        },
        levels: getLevelOption1(),
        leafDepth: 2,
        data: dtlData,
      };

      this.dianweiOptions = {
        backgroundColor: "transparent",
        title: {
          text: "",
        },
        toolbox: {
          show: false,
        },
        tooltip: {
          formatter: function (info) {
            var value = info.value;
            var treePathInfo = info.treePathInfo;
            var treePath = [];

            for (var i = 1; i < treePathInfo.length; i++) {
              treePath.push(treePathInfo[i].name);
            }
            if (treePath.length == 2) {
              treePath[1] = mapping[treePath[1]];
            }

            return [
              '<div class="tooltip-title">' +
                formatUtil.encodeHTML(treePath.join(" - ")) +
                "</div>",
              "表数量: " + formatUtil.addCommas(value),
            ].join("");
          },
        },
        series: [series0],
      };
    },
    goToMassage(row, event, column) {
      // 在这里处理行点击事件
      console.log("行点击事件：", row, event, column);
      this.$router.push("/tupuDiagnoise/fourth");
      this.selectIndex = row.index;
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (rowIndex == this.selectIndex) {
        return "success-row";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fault-content-3 {
  width: 95%;
  margin: 0 auto;
  height: calc(100vh - 180px);
  color: rgb(113, 203, 255);

  .display-flex {
    display: flex;
  }
  .bottomX {
    bottom: 0;
  }
  .subt {
    margin-left: 20px;
    color: rgba(194, 184, 92, 1);
    font-size: 18px;
  }
  .textsub {
    margin-left: 20px;
    color: rgba(255, 235, 59, 1);
    font-size: 18px;
    font-weight: 700;
  }
  .content-text {
    flex-wrap: wrap;
    font-size: 16px;
    color: #fff;
    margin: 10px;
  }
  .text-s {
    font-size: 20px;
    color: rgb(194, 213, 238);
    font-weight: 700;
    margin: 5px 10px;
    line-height: 50px;
  }
  .weather {
    width: 46%;
    height: 50px;
    margin-top: 5px;
    border: 1px solid rgba(27, 217, 247, 1);
    .text {
      font-size: 24px;
      line-height: 45px;
      font-weight: 700;
      margin-left: 30px;
    }
  }
  .content-1 {
    height: calc(100vh - 150px);
    overflow-y: scroll;
    .top {
      width: 100%;
      height: 50%;
      display: grid;
      grid-template-columns: 0.8fr 1fr 1fr;
      .chart {
        width: 100%;
        height: 100%;
      }
      .left {
        margin: 5px;
        border: 1px solid rgba(27, 217, 247, 1);
      }
      .center {
        margin: 5px;
        display: grid;
        grid-template-rows: 0.5fr 1fr 0.8fr;
        justify-content: center;
        align-items: center;
        .status {
          position: relative;
          width: 150px;
          height: 53px;
          border-radius: 10px;
          background-color: rgba(159, 0, 0, 0.46);
          display: flex;
          .circle {
            width: 20px; /* 圆的宽度 */
            height: 20px; /* 圆的高度 */
            margin: 16px 0 0 10px;
            background-color: rgba(183, 28, 28, 1); /* 圆的背景颜色 */
            border-radius: 50%; /* 使边角圆滑，形成圆形 */
          }
          .text {
            color: #fff;
            font-size: 24px;
            margin: 8px 0 0 10px;
          }
        }
        .status1 {
          position: relative;
          width: 150px;
          height: 53px;
          border-radius: 10px;
          background-color: rgba(182, 92, 7, 0.46);
          display: flex;
          .circle1 {
            width: 20px; /* 圆的宽度 */
            height: 20px; /* 圆的高度 */
            margin: 16px 0 0 10px;
            background-color: rgba(183, 111, 28, 0.733); /* 圆的背景颜色 */
            border-radius: 50%; /* 使边角圆滑，形成圆形 */
          }
          .text {
            color: #fff;
            font-size: 24px;
            margin: 8px 0 0 10px;
          }
        }
        .position1 {
          position: absolute;
          top: 10%;
          left: 50%;
        }
        .position2 {
          position: absolute;
          top: 15%;
          left: 38%;
        }
        .position3 {
          position: absolute;
          top: 20%;
          right: 45%;
        }
        .position4 {
          position: absolute;
          top: 31%;
          left: 35%;
        }
        .position5 {
          position: absolute;
          top: 30%;
          left: 53%;
        }
        .position6 {
          position: absolute;
          top: 25%;
          left: 41%;
        }
        .btn{
          height: 40px;
          margin-top: 10px;
          margin-left: 30px;
          font-size: 18px;
          font-weight: 700;
        }
      }
      .right {
        margin: 5px;
        border: 1px solid rgba(27, 217, 247, 1);
      }
      .texts {
        color: aliceblue;
        font-size: 22px;
      }
      .textsub {
        color: #cac220;
        font-size: 18px;
      }
    }
    .bottom {
      height: 48%;
      display: grid;
      grid-template-columns: 1.2fr 1fr;
      .left {
        margin: 5px;
        border: 1px solid rgba(27, 217, 247, 1);
        .chart {
          width: 50%;
          height: calc(15vh);
        }
        .chart-qushi {
          width: 100%;
          height: calc(15vh);
        }
        .pages {
          font-size: 16px;
          color: #5fc5e4;
          float: right;
          margin-right: 40px;
        }
      }
      .right {
        margin: 5px;
        border: 1px solid rgba(27, 217, 247, 1);
        .chart {
          width: 100%;
          height: 90%;
        }
      }

      .texts {
        color: aliceblue;
        font-size: 22px;
      }
    }
  }

  .display-align {
    align-items: center;
  }

  .display-justify {
    justify-content: space-between;
  }
  /deep/ .el-select {
    margin-top: 10px;
  }
  
  /deep/ .el-select {
    margin-top: 10px;
  }
  /deep/ .el-table th {
    color: rgb(236, 227, 227);
    padding: 0 ;
    line-height: 1px;
    text-align: center;
    background-color: #339bcf50 !important;
  }
  /deep/ .el-table tr {
    background-color: transparent !important;
    text-align: center !important;
    padding: 0
  }
  /deep/ .el-table__cell {
    padding: 0px 0px;
  }
  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid #555f64;
  }
  /deep/ .el-table {
    color: rgb(202, 224, 231);
    background-color: rgba(0, 60, 200, 1);
  }

  /deep/ .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: "";
    position: absolute;
    background-color: #174bdaa2;
    z-index: 1;
  }

  /deep/ .el-table--border th,
  .el-table__fixed-right-patch {
    border-bottom: 1px solid #dd7e11a2;
  }
  /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(139, 71, 6, 0.87); //颜色必须是rgb
  }
  // 必须加组件穿透和优先级最高
  /deep/ .el-table .success-row {
    background-color: rgba(139, 71, 6, 0.87) !important;
  }
}
</style>