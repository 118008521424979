<template>
  <div>
    <Echart
      :options="options"
      :id="id"
      :height="height"
      :width="'100%'"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {
      },
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      dafault: "echartsi",
    },
    height: {
      type: String,
      dafault: "100%",
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = newData;
      },
      immediate: true,
      deep: true,
    },
  }
};
</script>
